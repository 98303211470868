/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable */
import { NextSeo } from "next-seo";
import Image from "next/image";
// import { useEffect, useState } from 'react';
// import useSWR from 'swr';
import Head from "next/head";
// import { useState } from 'react';
import Link from "next/link";
import { apiURL } from "../utils/AppConfig.ts";
// eslint-disable-next-line import/extensions
import Navbar from "../components/home/nav.home";
import BrandSection from "../modules/brand";

// function classNames(...classes: string[]) {
//   return classes.filter(Boolean).join(' ');
// }

const logoBahagiaDay = "/assets/images/logobahagiaday.svg";

const Index = ({ data }) => {
  // console.log(data);
  const host =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const hosts = {
    "https://bahagia.day": "https://official.bahagia.day",
    "https://www.bahagia.day": "https://official.bahagia.day",
  };
  const currentHost = hosts[host] || host;
  // console.log(currentHost);

  const currentYear = new Date().getFullYear();

  if (!data) {
    return (
      <div className="grid min-h-screen content-center">
        <div className="flex justify-center">
          <p>Brand Partner Tidak Ditemukan</p>
        </div>
      </div>
    );
  }

  const subscriptionDueDate = new Date(data.subscriptionDue);

  // Calculate daysUntilDue
  const daysUntilDue = Math.ceil(
    (subscriptionDueDate.getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24),
  );

  if (daysUntilDue === null || daysUntilDue < 0) {
    return (
      <div className="grid min-h-screen content-center">
        <div className="flex justify-center">
          <p>Brand Partner Tidak Aktif</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head>
        <link rel="icon" href={data.logo ? data.logo.url : logoBahagiaDay} />
      </Head>
      <NextSeo
        title={data?.name}
        description={data?.tagline}
        canonical={currentHost}
        openGraph={{
          url: currentHost,
          title: data?.name,
          description: data?.tagline,
          images: [
            {
              url: data.logo ? data.logo.url : logoBahagiaDay,
              width: 800,
              height: 600,
              alt: "Og Image Alt",
              type: "image/jpeg",
            },
          ],
          siteName: "bahagia.day",
        }}
        twitter={{
          handle: "Bahagia.day",
          site: "Bahagia.day",
          cardType: "summary_large_image",
        }}
      />
      <div className="flex justify-center font-roboto">
        <div className="flex min-h-screen w-full max-w-[500px] justify-center bg-white">
          <div className="grid h-auto w-full pt-2">
            <div className="flex w-full flex-col items-center justify-start gap-6 mt-[103px]">
              <Navbar data={data} />

              {/* {(data.about || data.about !== '') && (
                <p className="w-fit shrink-0 grow-0 text-center text-[14px] text-[#38465b]">
                  {data.about}
                </p>
              )} */}
              <div className="flex shrink-0 grow-0 flex-col items-center justify-start gap-4 self-stretch mt-4">
                {data.home?.length >= 1 ? (
                  <BrandSection data={data.home} brand={data} />
                ) : (
                  <div className="w-full flex flex-col gap-4 p-4">
                    {data.link.length >= 1 ? (
                      data.link.map((dataBrandLink, i) => (
                        <a
                          className="w-full"
                          key={i}
                          href={dataBrandLink.url ? dataBrandLink.url : null}
                          target="_blank"
                        >
                          <div className="flex shrink-0 grow-0 flex-col items-start justify-start self-stretch overflow-hidden rounded-[12px] bg-[#F2F2F2]">
                            {dataBrandLink.image &&
                              dataBrandLink.image.formats && (
                                <Image
                                  src={dataBrandLink.image.url}
                                  alt="cover-image"
                                  // layout="responsive"
                                  className="w-full object-cover h-auto"
                                  width={1920}
                                  height={1080}
                                />
                              )}
                            {(dataBrandLink.name ||
                              dataBrandLink.description) && (
                              <div className="relative flex shrink-0 grow-0 flex-col items-start justify-start gap-1.5 self-stretch p-4">
                                {dataBrandLink.name && (
                                  <p className="w-fit shrink-0 grow-0 self-stretch text-left text-[20px] font-bold text-[#38465b]">
                                    {dataBrandLink.name}
                                  </p>
                                )}
                                {dataBrandLink.description && (
                                  <p className="w-fit shrink-0 grow-0 text-left text-[14px] text-[#7c7d8a]">
                                    {dataBrandLink.description}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </a>
                      ))
                    ) : (
                      <p className="w-fit shrink-0 grow-0 text-center text-[14px] text-[#38465b]">
                        Selamat datang di {data && data.name}
                      </p>
                    )}
                  </div>
                )}

                {["official-dev.bahagia.day", "official.bahagia.day"].includes(
                  data?.domain,
                ) && (
                  <div className="flex flex-row gap-4 pt-4">
                    <Link
                      href="/company"
                      target="_blank"
                      className="w-fit shrink-0 grow-0 text-center text-[14px] text-[#38465b] pt-0"
                    >
                      About Us
                    </Link>
                    <Link
                      href="/company/terms"
                      target="_blank"
                      className="w-fit shrink-0 grow-0 text-center text-[14px] text-[#38465b] pt-0"
                    >
                      Terms
                    </Link>
                    <Link
                      href="/company/privacy"
                      target="_blank"
                      className="w-fit shrink-0 grow-0 text-center text-[14px] text-[#38465b] pt-0"
                    >
                      Privacy
                    </Link>
                    <Link
                      href="/company/faq"
                      target="_blank"
                      className="w-fit shrink-0 grow-0 text-center text-[14px] text-[#38465b] pt-0"
                    >
                      FAQ
                    </Link>
                  </div>
                )}

                <p className="w-fit shrink-0 grow-0 text-center text-[14px] text-[#38465b] pb-4">
                  {currentYear} © {data && data.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

export const getServerSideProps = async (context) => {
  const hosts = {
    "bahagia.day": "official.bahagia.day",
    "www.bahagia.day": "official.bahagia.day",
  };
  const { host } = context.req.headers;
  const currentHost = hosts[host] || host;

  const byBrandDomain = `filters[domain]=${currentHost}`;
  const linkPopulation = `populate[link][populate]=*`;
  const catalogPopulation = `populate[catalog][populate]=*`;
  const homeSectionPopulation = `populate[home][populate][content][populate][image][populate]=url`;
  const logoPopulation = "populate[logo][field][0]=url";
  const paramsKey = `${byBrandDomain}&${linkPopulation}&${catalogPopulation}&${logoPopulation}&${homeSectionPopulation}`;

  // Add headers for the fetch request
  const headers = {
    Authorization: `Bearer ${process.env.API_SECRET}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(`${apiURL}/api/brands?${paramsKey}`, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.status}`);
    }

    const dataRes = await response.json();
    const data = dataRes.data[0] || null;

    return {
      props: {
        data,
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in server access:", error);

    return {
      props: {
        data: null,
      },
    };
  }
};
